<template>
	<iframe
		src="https://img.wookah.pl/documents/WOOKAH_Portfolio_2024.pdf"
		type="application/pdf"
		class="w-100 h-100 border-1s"
	>
		Oops! an error has occurred.
	</iframe>
</template>
<script>

export default {
	name: 'OtherPortfolio',
	components: {},
	computed: {},
	data: () => ({}),
	methods: {},
	created() {
	}
};
</script>
